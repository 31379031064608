import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import Axios from "axios";


Vue.prototype.$http = Axios;
//Axios.defaults.baseURL = 'http://localhost:8000/api/';
Axios.defaults.baseURL = 'https://courier.violaflo.ru/api/';
Axios.defaults.validateStatus = function (status) {
  return true;
};

Axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Authorization': 'Bearer ' + localStorage.getItem('token'),
}

Axios.interceptors.response.use(async (response) => {
  if (response.status === 401) {
    localStorage.removeItem('token');
    await router.push('/pages/login');
  }

  return response;
});

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

if (!localStorage.getItem('token')) {
  router.push('/pages/login').then(() => {
    new Vue({
      el: '#app',
      router,
      store,
      icons,
      template: '<App/>',
      components: {
        App
      }
    })
  });
} else {
  new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
      App
    }
  })
}

